<template>
  <v-content>
    <div class="pa-4">
      <span class="fontheaderBold">ตะกร้าสินค้า</span>
    </div>
    <v-divider></v-divider>
    <div class="d-flex align-center justify-space-between">
      <v-checkbox
        class="pb-4"
        hide-details
        v-model="allSelected"
        label="เลือกทั้งหมด"
        color="#007fdd"
        style="margin-left: 10px;"
      >
      </v-checkbox>
      <span class="v-label theme--light mx-4" style="font-size: 20px" @click="modalDelete">
        <v-icon>delete</v-icon>ลบ
      </span>
    </div>
    <div v-if="cart">
      <div v-for="(item, index) in cart.products" :key="index">
        <v-divider />
        <v-row class="mx-0">
          <v-col :cols="4">
            <div class="imageBox">
              <v-img :src="item.image" contain width="100" height="100" />
              <v-checkbox
                v-model="selected"
                :value="item"
                class="selected"
                color="#007fdd"
                hide-details
              />
            </div>
          </v-col>
          <v-col :cols="8">
            <span class="titleName">{{ item.name }}</span>
            <span class="description">{{ item.description }}</span>
            <v-row>
              <v-col :cols="7">
                <v-text-field
                  v-model.number="item.qty"
                  outlined
                  dense
                  hide-details
                  single-line
                  type="number"
                  append-outer-icon="add"
                  @click:append-outer="increment({ index: index, qty: item.qty })"
                  prepend-icon="remove"
                  @click:prepend="decrement({ index: index, qty: item.qty })"
                  @change="changeQty({ index: index, qty: item.qty })"
                  id="customInput"
                />
              </v-col>
              <v-col :cols="5" class="d-flex align-end px-0">
                <span class="titleName">THB {{ item.subtotal.toLocaleString() }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="pb-0 my-5 py-2">
      <v-divider></v-divider>
      <div class="px-6 py-2 pb-8 d-flex justify-space-between">
        <span class="titleName">ยอดรวม</span>
        <span class="price">THB {{ totalPrice.toLocaleString() }}</span>
      </div>
    </div>
    <div class="fixButtom">
      <ButtonGroup
        :funcLeft="() => this.$router.push('/product')"
        :funcRight="next"
        titleLeft="สั่งสินค้าเพิ่ม"
        titleRight="ถัดไป"
      />
    </div>
  </v-content>
</template>

<script>
import ButtonGroup from '../components/ButtonGroup'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Cart',
  data: () => {
    return {
      selected: [],
      allSelected: null,
      all: true,
    }
  },
  components: {
    ButtonGroup,
  },
  computed: {
    ...mapGetters({
      cart: 'getCart',
    }),
    totalPrice() {
      if (this.selected.length === 0) return 0
      return this.selected.map(item => item.subtotal).reduce((acc, value) => acc + value, 0)
    },
  },
  methods: {
    ...mapActions(['getCart', 'putLogger']),
    async changeQty(v) {
      if (v.qty && v.qty > 0) {
        this.$store.commit('changeQty', { index: v.index, qty: v.qty })
      } else {
        this.$modal.show({
          title: 'สินค้าไม่ควรเหลือน้อยกว่า 1 ชิ้น',
          isOne: true,
        })
        this.$store.commit('changeQty', { index: v.index, qty: 1 })
      }
      await this.putLogger({
        action: 'changeQty',
        data: {
          cart: this.$store.state.cart.products[v.index],
          payload: { qty: v.qty },
        },
      })
    },
    increment(v) {
      this.$store.commit('increment', v.index)
    },
    decrement(v) {
      if (v.qty > 1) this.$store.commit('decrement', v.index)
      else {
        this.$modal.show({
          title: 'สินค้าไม่ควรเหลือน้อยกว่า 1 ชิ้น',
          isOne: true,
        })
      }
    },
    selectAll() {
      if (this.allSelected) {
        if (this.selected.length === 0) {
          this.cart.products.map(item => this.selected.push(item))
        } else {
          this.selected = []
          this.cart.products.map(item => this.selected.push(item))
        }
      } else {
        this.selected = []
      }
    },
    async modalDelete() {
      if (this.selected.length !== 0) {
        this.$modal.show({
          title: 'ต้องการลบสินค้าออกจากตะกร้า?',
          onConfirm: () => this.deleteItem(),
        })
      } else {
        this.$modal.show({
          title: 'กรุณาเลือกสินค้า',
          isOne: true,
        })
      }
    },
    async deleteItem() {
      const item = this.selected.map(item => {
        return {
          sku_id: item.sku,
        }
      })
      try {
        this.$loading(true)
        await this.$axios({
          method: 'POST',
          url: '/api/cart/remove',
          data: {
            social_id: this.$store.state.tokenId,
            store_id: this.$store.state.storeId,
            remove_product: {
              products: item,
            },
          },
        })
        this.selected = []
        const cart = await this.getCart()
        if (cart.status === 'failed') {
          this.$modal.show({
            title: `ERROR: ${cart.message}`,
            isOne: true,
            onConfirm: () => this.$router.push('/listshop'),
          })
        } else {
          if (!this.$store.state.storeId) {
            this.$store.commit('setStoreId', cart.merchant_detail[0].store_id)
            this.$store.commit('setStoreName', cart.merchant_detail[0].name_store)
          }
          this.$store.commit('setCart', cart)
        }
        this.selectAll()
        this.allSelected = true
      } catch (e) {
        this.$modal.show({
          title: `ERROR: ${e}`,
          isOne: true,
        })
      } finally {
        this.$loading(false)
      }
    },
    async next() {
      if (this.selected.length !== 0) {
        const item = this.selected.map(item => {
          return {
            sku_id: item.sku,
            qty: item.qty,
          }
        })
        try {
          this.$loading(true)
          await this.putLogger({
            action: 'onClickNext',
            data: {
              item,
              cart: this.$store.state.cart,
            },
          })
          const res = await this.$axios({
            method: 'POST',
            url: '/api/cart/replace',
            data: {
              social_id: this.$store.state.tokenId,
              store_id: this.$store.state.storeId,
              new_product: {
                products: item,
              },
            },
          })
          if (res.data.status === 'success') {
            this.$router.push('/confirmcart')
          }
        } catch (e) {
          this.$modal.show({
            title: e,
            isOne: true,
          })
        } finally {
          this.$loading(false)
        }
      } else {
        this.$modal.show({
          title: 'กรุณาเลือกสินค้า',
          isOne: true,
        })
      }
    },
  },
  watch: {
    allSelected(n) {
      if (this.selected.length === 0) this.all = true
      if (n && this.cart.products.length !== this.selected.length && !this.all) {
        this.all = true
      }
      if (this.all) this.selectAll()
    },
    selected(n) {
      if (this.cart.products.length !== n.length) {
        this.all = false
        this.allSelected = false
      } else {
        this.all = true
        this.allSelected = true
      }
    },
  },
  async mounted() {
    try {
      this.$loading(true)
      const cart = await this.getCart()
      if (cart.status === 'failed') {
        this.$modal.show({
          title: 'ยังไม่มีสินค้าในตะกร้า',
          isOne: true,
          onConfirm: () => this.$router.push('/listshop'),
          onClose: () => this.$router.push('/listshop'),
        })
      } else {
        if (!this.$store.state.storeId) {
          this.$store.commit('setStoreId', cart.merchant_detail[0].store_id)
          this.$store.commit('setStoreName', cart.merchant_detail[0].name_store)
          this.$store.commit('setStoreType', cart.merchant_detail[0]?.shop_type)
        }
        this.$store.commit('setCart', cart)
      }
    } catch (e) {
      this.$modal.show({
        title: `ERROR: ${e}`,
        isOne: true,
      })
    } finally {
      this.$loading(false)
    }
    this.allSelected = true
  },
}
</script>

<style lang="scss" scoped>
.selected {
  position: absolute;
  top: -25px;
  left: -5px;
}
.imageBox {
  position: relative;
  border: solid 1px #e0e0e0;
}
</style>
